import { ErrorBoundary } from "react-error-boundary";
import React, { useState, useCallback, useEffect } from 'react'
import { parse, format } from 'date-fns'
import { IconCheck, IconPointFilled } from '@tabler/icons-react'
import { atom, Provider, useAtom } from 'jotai'
import './styles.css'

// const showUndefinedAtom = atom(false)
// const showEmptyStringsAtom = atom(false)
// const showEmptyArraysAtom = atom(false)
const showUndefined = false
const showEmptyStrings = false
const showEmptyArrays = false

export const ObjectToTableComponent = (props: { data: any }) => {
  const [defaultOpenLevels, setDefaultOpenLevels] = useState(5)
  const [showOpenLevelsSlider, setShowOpenLevelsSlider] = useState(false)

  return (
    <ErrorBoundary fallback={<div>Something went wrong <pre>{JSON.stringify(props.data, null, 2)}</pre></div>}>
      <>
        {showOpenLevelsSlider && <div className="slider-container">
          <label htmlFor="open-levels">Open Levels: {defaultOpenLevels}</label>
          <input
            type="range"
            id="open-levels"
            min="1"
            max="10"
            step="1"
            value={defaultOpenLevels}
            onChange={(e) => setDefaultOpenLevels(Number(e.target.value))}
          />
        </div>
        }
        <Provider>
          <GenerateJsObjectHtml jsonData={props.data} defaultOpenLevels={defaultOpenLevels} />
        </Provider>
      </>
    </ErrorBoundary>
  )
}

const isISODate = (value: any) => {
  return typeof value === 'string' && value.length >= 20 && value.length <= 29 && value[4] === '-' && value[7] === '-' && value[10] === 'T'
}

const formatDate = (value: any) => {
  const date = value instanceof Date ? value : parse(value, "yyyy-MM-dd'T'HH:mm:ss.SSSX", new Date());
  const formattedDate = format(date, 'EEE d MMM yyyy');
  const time = format(date, 'h:mma');
  return time === '12:00AM' ? formattedDate : `${formattedDate} ${time}`;
}

const formatPrimitive = (value: any) => {
  // const [showUndefined] = useAtom(showUndefinedAtom)
  // const [showEmptyStrings] = useAtom(showEmptyStringsAtom)

  if (value === undefined) return showUndefined ? 'undefined' : ''
  if (Number.isNaN(value)) return 'NaN'
  if (value instanceof Date) {
    return formatDate(value)
  }
  if (isISODate(value)) {
    return formatDate(value)
  }
  if (value === '') return showEmptyStrings ? <span className="empty-string">""</span> : ''
  if (typeof value === 'string') {
    return makeUrlsClickable(value)
  }
  return value
}

const makeUrlsClickable = (text: any) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g
  const parts = text.split(urlRegex)
  return parts.map((part: any, index: any) => {
    if (part.match(urlRegex)) {
      return (
        <a key={index} href={part} target="_blank" rel="noopener noreferrer">
          {part}
        </a>
      )
    }
    return part
  })
}

const getCellType = (content: any) => {
  if (content === undefined) return 'undefined'
  if (content === null) return 'null'
  if (Number.isNaN(content)) return 'nan'
  if (content instanceof Date) return 'date'
  const type = typeof content
  if (type === 'number') return 'number'
  if (type === 'boolean') return 'boolean'
  if (type === 'string') {
    if (isISODate(content)) return 'date'
    if (/^-?\d+(\.\d+)?$/.test(content)) return 'number-string'
    return 'string'
  }
  if (type === 'object') return 'complex'
  return 'other'
}

const isObject = (value: any) => typeof value === 'object' && value !== null && !Array.isArray(value)
const isComplexType = (value: any) => typeof value === 'object' && value !== null
const isPrimitive = (value: any) => !isComplexType(value)

const renderContent = (value: any, depth: any, defaultOpenLevels: any) => {
  if (Array.isArray(value)) return <ArrayTable arr={value} depth={depth} defaultOpenLevels={defaultOpenLevels} />
  if (isObject(value)) return <ObjectTable json={value} depth={depth} defaultOpenLevels={defaultOpenLevels} />
  return formatPrimitive(value)
}

const TableCell = ({ content, depth, defaultOpenLevels }: { content: any; depth: any; defaultOpenLevels: any }) => {
  const cellType = getCellType(content)
  let cellContent

  switch (cellType) {
    case 'complex':
      cellContent = renderContent(content, depth + 1, defaultOpenLevels)
      break
    case 'date':
    case 'number':
    case 'number-string':
    case 'string':
    case 'undefined':
    case 'nan':
      cellContent = formatPrimitive(content)
      break
    case 'boolean':
      cellContent = content ? <IconCheck size={10} stroke={2} /> : <IconPointFilled size={5} stroke={1} />
      break
    default:
      cellContent = content === null ? 'null' : content
  }

  return <td className={`${cellType}-cell`}>{cellContent}</td>
}

const TableHeader = ({ content }: { content: any }) => <th>{content}</th>

const PrimitiveArrayTable = ({ arr }: { arr: any }) => (
  <table className="array-table">
    <tbody>
      <tr>
        {arr.map((item: any, index: any) => {
          const cellType = getCellType(item)
          return (
            <td key={index} className={`${cellType}-cell`}>
              {formatPrimitive(item)}
            </td>
          )
        })}
      </tr>
    </tbody>
  </table>
)

const BlueHeader = ({ isArray, itemCount, isCollapsed, onToggle }: { isArray: boolean; itemCount: number; isCollapsed: boolean; onToggle: () => void }) => (
  <div className="blue-header" data-toggleable="true" onClick={onToggle}>
    <span className="toggle-indicator">{isCollapsed ? '▶' : '▼'}</span>
    {isArray ? `Array ` : `Object `}
    <span className="item-count">{itemCount} items</span>
  </div>
)

const CollapsibleWrapper = ({
  children,
  depth,
  defaultOpenLevels,
  isArray,
  itemCount
}: {
  children: React.ReactNode
  depth: number
  defaultOpenLevels: number
  isArray: boolean
  itemCount: number
}) => {
  const [isCollapsed, setIsCollapsed] = useState(depth >= defaultOpenLevels)

  useEffect(() => {
    setIsCollapsed(depth >= defaultOpenLevels)
  }, [depth, defaultOpenLevels])

  const toggleCollapse = useCallback(() => {
    setIsCollapsed((prev) => !prev)
  }, [])

  return (
    <div className={`blue-wrapper ${isCollapsed ? 'collapsed' : ''}`} data-depth={depth}>
      <BlueHeader isArray={isArray} itemCount={itemCount} isCollapsed={isCollapsed} onToggle={toggleCollapse} />
      <div className="content">{children}</div>
    </div>
  )
}

const ArrayTable = ({ arr, depth = 0, defaultOpenLevels }: { arr: any[]; depth: number; defaultOpenLevels: number }) => {
  // const [showEmptyArrays] = useAtom(showEmptyArraysAtom)
  if (arr.length === 0) return showEmptyArrays ? <div className="empty-array">[]</div> : ''
  if (arr.every(isPrimitive)) return <PrimitiveArrayTable arr={arr} />

  const headers = Array.from(new Set(arr.flatMap((item: any) => (isObject(item) ? Object.keys(item) : ['Value']))))

  return (
    <CollapsibleWrapper depth={depth} defaultOpenLevels={defaultOpenLevels} isArray={true} itemCount={arr.length}>
      <table className="content">
        <thead>
          <tr>
            {headers.map((header, index) => (
              <TableHeader key={`header-${index}`} content={header} />
            ))}
          </tr>
        </thead>
        <tbody>
          {arr.map((item: any, rowIndex: any) => (
            <tr key={`row-${rowIndex}`}>
              {headers.map((header: any, colIndex: any) => (
                <TableCell
                  key={`cell-${rowIndex}-${colIndex}`}
                  content={isObject(item) ? item[header] : header === 'Value' ? item : ''}
                  depth={depth}
                  defaultOpenLevels={defaultOpenLevels}
                />
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </CollapsibleWrapper>
  )
}

const ObjectTable = ({ json, depth = 0, defaultOpenLevels }: { json: any; depth: number; defaultOpenLevels: number }) => {
  if (json === undefined) return <div>Error: Invalid JSON data</div>
  if (json === null) return <div>null</div>
  if (Array.isArray(json)) return <ArrayTable arr={json} depth={depth} defaultOpenLevels={defaultOpenLevels} />

  const entries = Object.entries(json)

  return (
    <CollapsibleWrapper depth={depth} defaultOpenLevels={defaultOpenLevels} isArray={false} itemCount={entries.length}>
      <table className="content">
        <tbody>
          {entries.map(([key, value], index) => (
            <tr key={`row-${index}`}>
              <TableHeader content={key} />
              <TableCell content={value} depth={depth} defaultOpenLevels={defaultOpenLevels} />
            </tr>
          ))}
        </tbody>
      </table>
    </CollapsibleWrapper>
  )
}

export function GenerateJsObjectHtml({ jsonData, defaultOpenLevels = 2 }: { jsonData: any; defaultOpenLevels: number }) {
  if (jsonData === undefined) {
    return <div>undefined</div>
  }

  if (jsonData === null) {
    return <div>null</div>
  }

  if (typeof jsonData === 'string') {
    return <div className="plain-string">{jsonData}</div>
  }

  if (jsonData instanceof Date) {
    return <div className="plain-date">{formatDate(jsonData)}</div>
  }

  return (
    <div className="objectExplorerTable">
      <ObjectTable json={jsonData} depth={0} defaultOpenLevels={defaultOpenLevels} />
    </div>
  )
}
