
import { Paper, Button, Text, Title, Group, Alert } from '@mantine/core';
import { useMemo, useState } from 'react';
import urlJoin from 'url-join';
// import { crownBridgePrebookingUrl, denturePrebookingUrl } from './functions';
import * as Sentry from "@sentry/browser";
import type { RetoolPrebookBody } from './types';
import type { PrebookType } from '../helper';


async function getIdFromCancelReferenceId(baseUrl: string, cancelReferenceId: string) {
    const filterUrl = new URL(baseUrl);
    filterUrl.searchParams.append('filter[cancelReferenceId]', cancelReferenceId);
    const req = await fetch(filterUrl)
    const res = await req.json()
    const id = res.formResponses?.[0]?.id
    return [id, res.formResponses?.[0]] as const
}


async function cancelBooking(cancelReferenceId: string) {


    const updatedPrebookingReq = await fetch(`https://api.retool.com/v1/workflows/d075a334-46fe-4f24-930a-46dd2e434687/startTrigger?workflowApiKey=retool_wk_3bc5a1ce2e6b4ccda84634fc8fd9e181`, { method: 'POST', body: JSON.stringify({ cancelReferenceId }), headers: { "Content-Type": "application/json", }, });
    if (!updatedPrebookingReq.ok) {
        throw new Error("Failed to cancel prebooking")
    }

    const updatedPrebooking: RetoolPrebookBody = (await updatedPrebookingReq.json())[0]

    console.log("updatedPrebooking")
    console.log(JSON.stringify(updatedPrebooking, null, 2))

    const body: PrebookType = {
        emailAddress: updatedPrebooking.emailAddress,
        dentistPracticeName: updatedPrebooking.dentistPracticeName,
        patientName: updatedPrebooking.patientName,
        returnDate: updatedPrebooking.returnDate || '',
        dateSend: updatedPrebooking.dateSend || undefined,
        impressionType: updatedPrebooking.impressionType,
        teethToBeRestored: updatedPrebooking.isCrownBridge ? updatedPrebooking.teeth : undefined,
        restorationType: updatedPrebooking.isCrownBridge ? updatedPrebooking.item : undefined,
        materialType: updatedPrebooking.materialType || undefined,
        comments: updatedPrebooking.comments,
        bookingMadeBy: updatedPrebooking.bookingMadeBy,
        referenceId: updatedPrebooking.referenceId,
        cancelReferenceId: updatedPrebooking.cancelReferenceId,
        dentureWhatItem: updatedPrebooking.isCrownBridge ? undefined : updatedPrebooking.item,
        dentureUpperOrLower: updatedPrebooking.isCrownBridge ? undefined : updatedPrebooking.teeth,
        dentureTryInOrStraitToFinish: updatedPrebooking.tryInOrStraightToFinish || undefined,
        type: "Cancel",
        region: updatedPrebooking.region,
        isCrownAndBridge: updatedPrebooking.isCrownBridge,
        acceptedDisclaimers: updatedPrebooking.acceptedDisclaimers,
        instructionsUrl: '',
        showInstructions: false,
        itemValue: updatedPrebooking.itemValue,
        mainDate: updatedPrebooking.mainDate
    };





    const mailResponseRequest = fetch(`/.netlify/functions/submit-form`, { method: 'POST', body: JSON.stringify(body, null, 2), headers: { "Content-Type": "application/json", }, });
    await mailResponseRequest;
}

export function PreCancelQuestion() {
    const [yesNo, setYesNo] = useState(undefined as undefined | boolean);

    if (yesNo === true) {
        return (
            <Paper shadow="xs" p="xl" mt={"md"} radius={"md"}>

                <Title size="h2" mb={"xs"}>Call us to cancel</Title>
                <Text>
                    Because you've already sent the impression for this case you will need to call us to cancel on 09 478 3373
                </Text>

                <Alert mt={"md"} variant="light" color="yellow" title="Cancellation charges" >
                    If the case has been started, you will be invoiced for the work that has been done up until the point we receive the cancellation request.
                </Alert>
            </Paper>
        )
    }
    if (yesNo === false) {
        return (
            <CancelPrebooking />
        )
    }

    return (
        <Paper shadow="xs" p="xl" mt={"md"} radius={"md"}>
            <Title size="h2" mb={"xs"}>Pre-booking Cancellation</Title>
            <Text>
                Have you sent us the impressions for this case?
            </Text>
            <Group>

                <Button
                    color='dark'
                    mt={"md"}
                    onClick={() => {
                        setYesNo(true)
                    }}
                >
                    Yes
                </Button>
                <Button
                    variant='outline'
                    color='dark'
                    mt={"md"}
                    onClick={() => {
                        setYesNo(false)
                    }}
                >
                    No
                </Button>
            </Group>
        </Paper>
    )
}

export function CancelPrebooking() {

    const cancelId = useMemo(() => {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get('cancel')
    }, [])

    const invalidCancelId = cancelId === null || cancelId?.length !== 12

    const [isSubmitLoading, setIsSubmitLoading] = useState(false);

    const [hasError, setHasError] = useState(false);

    const [success, setSuccess] = useState(false);


    if (invalidCancelId) {
        return (
            <Paper shadow="xs" p="xl" mt={"md"} radius={"md"}>
                <Title size="h2" mb={"xs"}>Error</Title>
                <Text>
                    There was a problem with your cancellation link. Please contact us on&nbsp;
                    <a href="tel: 094783373">09 478 3373</a> or email{" "}
                    <a href="mailto: admin@oralart.co.nz">admin@oralart.co.nz</a>
                </Text>
            </Paper>
        )
    }

    if (hasError) {
        return (
            <Paper shadow="xs" p="xl" mt={"md"} radius={"md"}>
                <Title size="h2" mb={"xs"}>Error</Title>
                <Text>
                    There was a problem cancelling your pre-booking. Please contact us on&nbsp;
                    <a href="tel: 094783373">09 478 3373</a> or email{" "}
                    <a href="mailto: admin@oralart.co.nz">admin@oralart.co.nz</a>
                </Text>
            </Paper>
        )
    }

    if (success) {
        return (
            <Paper shadow="xs" p="xl" mt={"md"} radius={"md"}>
                <Text>
                    Your pre-booking has been cancelled
                </Text>
            </Paper>
        )
    }

    return (
        <>
            <Paper shadow="xs" p="xl" mt={"md"} radius={"md"}>
                <Text mb={"xs"}>Are you sure you want to cancel your pre-booking?</Text>
                <Button disabled={invalidCancelId} loading={isSubmitLoading} onClick={async () => {
                    try {
                        setIsSubmitLoading(true);
                        await cancelBooking(cancelId)
                        setSuccess(true)
                    } catch (e) {
                        Sentry.captureException(e)
                        setHasError(true)
                    } finally {
                        setIsSubmitLoading(false);
                    }

                }}>Yes, Cancel Pre-booking</Button>

            </Paper>
        </>
    );
}
