import { MantineProvider, Box, Paper, CloseButton, Text, Loader, LoadingOverlay, Code } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useLocalStorage } from "usehooks-ts";
import { Suspense } from "react";


const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: Infinity,
        }
    }
})


function Banner() {
    const [showBanner, setShowBanner] = useLocalStorage("showDentureSaveBanner", true)
    // if url parameter contains &denture then show banner
    if (!window.location.search.includes("denture")) {
        return null
    }

    if (!showBanner) {
        return null;
    }
    return (
        <>
            <Box bg={"black"} py={"xs"} sx={{
                color: "white"
            }}>
                <Box sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                    <Box sx={{
                        flexGrow: 1
                    }} />
                    <Text px={"md"} >Save this denture pre-booking form as a bookmark to access it again. The url has <Code>?denture</Code> on the end to enable denture pre-bookings</Text>
                    <Box sx={{
                        flexGrow: 1
                    }} />
                    <CloseButton onClick={() => {
                        setShowBanner(false);
                    }} mr={"xs"} variant="filled" color="dark" aria-label="Close modal" />
                </Box>
            </Box>
        </>
    );
}


export function Base(props: { children: React.ReactNode }) {

    return (
        <QueryClientProvider client={queryClient}>
            <MantineProvider withGlobalStyles withNormalizeCSS>

                <Notifications />
                <Banner />
                <Box maw={700} mx="auto" mt={"md"} mb={"150px"} px={"sm"} >
                    <Suspense fallback={
                        <LoadingOverlay visible={true} overlayBlur={2} />
                    }>
                        <>
                            <Paper shadow="xs" p="md" radius={"md"}>
                                <img src="./oralart-logo.png" style={{ width: "100%", height: "auto" }} width="1600px" height="401px" />
                            </Paper>
                            {props.children}
                        </>
                    </Suspense>
                </Box>
            </MantineProvider>
        </QueryClientProvider >

    )
}